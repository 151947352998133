<template>
    <div>
        <NavigationTabs
                :children-routes="childrenRoutes"
        />
        <router-view/>
    </div>
</template>

<script>
    import profileChildren from "@/router/profile/children"
    import NavigationTabs from "@/components/common/NavigationTabs"
    import {mapGetters} from "vuex"
    import {intersection, size, filter} from "lodash"

    export default {
        name: "Profile",
        components: {
            NavigationTabs
        },
        computed: {
            ...mapGetters('auth', ['authorities']),
            ...mapGetters('impersonalization', ['mindControlledUser']),
            childrenRoutes() {
                return filter(profileChildren, it => this.mindControlledUser || !(size(intersection(this.authorities, ['ROLE_ADMIN', 'ROLE_ROOT_ADMIN', 'ROLE_MANAGER'])) > 0 && it.name === 'subscription'))
            },
        },
    }
</script>

<style lang="less" scoped>

</style>